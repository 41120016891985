@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-now {
  background-image: url("./assets/BG.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width : 500px)  {
  .bg-now {
    background-image: url("./assets/BGMob.png");
  }
}


.floating { 
  animation-name: floating;

  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  /* margin-left: 30px;
  margin-top: 5px; */
}

.floating-2 {
  animation-duration: 2s;
}

.floating-3 {
  animation-duration: 3s;
}

.floating-4 {
  animation-duration: 4s;
}

@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, -0px); }   
}